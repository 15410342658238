import React, { useEffect } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import moment from "moment";

const BoekingenTabelDetail = ({ boekingen }) => {
  const getstatus = (actief) => {
    if (actief === "Nieuw") {
      return <small className="badge bg-primary">Nieuw</small>;
    } else if (actief === "accept") {
      return <small className="badge bg-success">Geaccepteerd</small>;
    } else if (actief === "cancel") {
      return <small className="badge bg-danger text-dark">Geweigerd</small>;
    } else if (actief === "annuleren") {
      return <small className="badge bg-warning text-dark">Geannuleerd</small>;
    } else {
      return <small className="badge bg-info text-dark">{actief}</small>;
    }
  };

  const columns = [
    {
      dataField: "datum",
      text: "Datum",
      formatter: (cell, row) => {
        return moment(cell).fromNow();
      },
    },
    {
      dataField: "OrderID",
      text: "Order ID",
    },
    {
      dataField: "soort",
      text: "Ervaring",
      formatter: (cellContent, row) => {
        if (cellContent === "arrangement") {
          return row.arrangementdatum.ervaring.naam;
        } else if (cellContent === "ervaring") {
          return row.ervaringdatum.ervaring.naam;
        }
      },
    },
    {
      dataField: "klanten",
      text: "Naam",
      formatter: (cell, row) => {
        return row.klanten.voornaam + " " + (row.klanten.tussenvoegsel ? row.klanten.tussenvoegsel + " " : "") + row.klanten.achternaam;
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: getstatus,
    },
    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        if (row.soort === "arrangement") {
          return (
            <span>
              <Link className="btn btn-info" to={"/boekingen/show/arrangement/" + row._id}>
                Bekijk
              </Link>
            </span>
          );
        } else {
          return (
            <span>
              <Link className="btn btn-info" to={"/boekingen/show/" + row._id}>
                Bekijk
              </Link>
            </span>
          );
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
    },
  ];

  return (
    <ToolkitProvider
      keyField="_id"
      data={boekingen}
      columns={columns}
      search={{
        searchFormatted: true,
      }}
    >
      {(props) => (
        <div>
          <Search.SearchBar placeholder="Zoeken" {...props.searchProps} />
          <hr />
          <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
        </div>
      )}
    </ToolkitProvider>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, boekingen: state.boekingen.items };
}

export default connect(mapStateToProps, {})(BoekingenTabelDetail);
